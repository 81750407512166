import React from "react";
import { useState, useEffect } from "react";
import "./ReportCase.scss";
import axios from "../../axios";
import { useParams } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import Login from "../login/login";
import { Clear, setReportData } from "../../redux/Functions/actions";
import { CLEAR_DATA } from "../../redux/Functions/types";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import jwt from "jwt-decode";
toast.configure();
const ReportCase = (props) => {
  const [municipalities, setmunicipalities] = useState([]);
  const [agencyget, setagencyget] = useState([]);
  const [raport, setraport] = useState([]);
  const [user, setUser] = useState();
  const [userID, setUserID] = useState();
  const [reportType, setReportType] = useState("");
  const [city, setCity] = useState("");
  const [agency, setAgency] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [contactInfo, setContactInfo] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [languageEn, setLanguageEn] = useState("");
  const [community, setcommunity] = useState([]);
  const [communitySend, setcommunitySend] = useState("");
  const [Gjinia, setGjinia] = useState("");
  const [age, setage] = useState("");
  const [edukimi, setEdukimi] = useState("");
  const [picture, setPicture] = useState();
  const [employment, setEmployment] = useState("");
  const [errorVlaidation, seterrorVlaidation] = useState(false);
  const [mistake, setmistake] = useState(1);
  const [errorR, seterrorR] = useState();
  const [errorC, seterrorC] = useState();
  const [errorA, seterrorA] = useState();
  const [errorL, seterrorL] = useState();
  const [errorE, seterrorE] = useState();
  const [errorD, seterrorD] = useState();
  const [errorG, seterrorG] = useState();
  const [errorU, seterrorU] = useState();
  const [errorP, seterrorP] = useState();
  const [errorV, seterrorV] = useState();
  const [errorCO, seterrorCO] = useState();
  const [errorAd, seterrorAd] = useState();
  const [errorCon, seterrorCon] = useState();
  const [objToDB, setObjToDb] = useState();
  const [error, setError] = useState("");
  const [errorPassword, seterrorPassword] = useState("");
  const [wrong, setWrong] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginData, setLoginData] = useState({ isRegister: false });
  const currentDate = new Date(new Date().getTime())
    .toISOString()
    .substring(0, 10);

  const translateMe = (key, langId) => {
    let diqka = props?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };
  // const handleusername = (e) => {
  //   setUsername(e.target.value);
  //   setError("");
  // };
  // const handlepassword = (e) => {
  //   setPassword(e.target.value);
  //   seterrorPassword("");
  // };
  const handleReport = (e) => {
    setReportType(e.target.value);
    seterrorR("");
  };

  const handlecity = (e) => {
    setCity(e.target.value);
    seterrorC("");
  };
  const handleagency = (e) => {
    setAgency(e.target.value);
    seterrorA("");
  };

  const handledate = (e) => {
    setDate(e.target.value);
    seterrorD("");
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
    seterrorE("");
  };
  const handleContactInfo = (e) => {
    // const isPublished = e.target.value === 'true' ? true : false;
    // setContactInfo(isPublished);
    setContactInfo(e.target.value);

    seterrorCon("");
  };
  // console.log("test",contactInfo)
  const handleAddress = (e) => {
    setAddress(e.target.value);
    seterrorAd("");
  };
  const handleNumber = (e) => {
    setPhoneNumber(e.target.value);
  };
  const handleMessage = (e) => {
    setLanguageEn(e.target.value);
    seterrorL("");
  };
  const handlecommunity = (e) => {
    setcommunitySend(e.target.value);
    seterrorCO("");
  };
  const handlegjinia = (e) => {
    setGjinia(e.target.value);
    seterrorG("");
  };
  const handleEduaction = (e) => {
    setEdukimi(e.target.value);
    seterrorU("");
  };
  const handleEmployment = (e) => {
    setEmployment(e.target.value);
    seterrorP("");
  };
  const handleage = (e) => {
    setage(e.target.value);
    seterrorV("");
  };
  const handleImg = (event) => {
    const files = event.target.files;
    const maxSize = 100 * 1024 * 1024; 
  
    if (files && files[0] && files[0].size > maxSize) {
      event.target.value = null; 
      toast.error("File ka limitin 100MB.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      setPicture(files);
    }
  };
  

  const handleContinue = (e) => {
    e.preventDefault();

    let mistake = 0;
    let errorC = "";
    let errorR = "";
    let errorCO = "";
    let errorA = "";
    let errorL = "";
    let errorAd = "";
    let errorCon = null;
    let errorP = "";
    let errorD = "";
    let errorG = "";
    let errorU = "";
    let errorV = "";

    const fd = new FormData();
    for (let i = 0; i < picture?.length; i++) {
      fd.append("images", picture[i]);
    }
    // fd.append('images', picture)

    // props.setReportData(data2);
    if (reportType == "") {
      errorR = `${translateMe("selectOneOfOptions", props?.landId)}`;
      seterrorR(`${translateMe("selectOneOfOptions", props?.landId)}`);
    }
    if (city == "") {
      errorC = `${translateMe("selectOneOfOptions", props?.landId)}`;
      seterrorC(`${translateMe("selectOneOfOptions", props?.landId)}`);
      // setmistake(1);
    }
    if (communitySend == "") {
      errorCO = `${translateMe("selectOneOfOptions", props?.landId)}`;
      seterrorCO(`${translateMe("selectOneOfOptions", props?.landId)}`);
    }
    if (agency == "") {
      errorA = `${translateMe("selectOneOfOptions", props?.landId)}`;
      seterrorA(`${translateMe("selectOneOfOptions", props?.landId)}`);
    }
    if (languageEn == "") {
      errorL = `${translateMe("writeSomethingAboutCase", props?.landId)}`;
      seterrorL(`${translateMe("writeSomethingAboutCase", props?.landId)}`);
    }

    if (address == "") {
      errorAd = `${translateMe("writeAddress", props?.landId)}`;
      seterrorAd(`${translateMe("writeAddress", props?.landId)}`);
    }
    if (contactInfo == null) {
      errorCon = `${translateMe("chooseOneOfAnswers", props?.landId)}`;
      seterrorCon(`${translateMe("chooseOneOfAnswers", props?.landId)}`);
    }
    if (date == "") {
      errorD = `${translateMe("putDate", props?.landId)}`;
      seterrorD(`${translateMe("putDate", props?.landId)}`);
    }
    if (Gjinia == "") {
      errorG = `${translateMe("chooseOneOfAnswers", props?.landId)}`;
      seterrorG(`${translateMe("chooseOneOfAnswers", props?.landId)}`);
    }
    // if (edukimi == "") {
    //   errorU = `${translateMe("selectOneOfOptions", props?.landId)}`;
    //   seterrorU(`${translateMe("selectOneOfOptions", props?.landId)}`);
    // }
    // if (employment == "") {
    //   errorP = `${translateMe("selectOneOfOptions", props?.landId)}`;
    //   seterrorP(`${translateMe("selectOneOfOptions", props?.landId)}`);
    // }
    if (age == "") {
      errorV = `${translateMe("putAge", props?.landId)}`;
      seterrorV(`${translateMe("putAge", props?.landId)}`);
    }

    if (
      errorR != "" ||
      errorC != "" ||
      errorCO != "" ||
      errorA != "" ||
      errorL != "" ||
      errorAd != "" ||
      errorCon != null ||
      errorD != "" ||
      errorG != "" ||
      errorU != "" ||
      errorP != "" ||
      errorV != ""
    ) {
      mistake = 1;
    } else {
      mistake = 0;
    }

    let myObj = {};
    // console.log(mistake);
    if (
      loginData?.isRegister === false &&
      mistake == 0 &&
      window.localStorage.getItem("token") == null
    ) {
      const returnedTarget = Object.assign(myObj, objToDB);
      returnedTarget.username = loginData.username;
      returnedTarget.pass = loginData.pass;
      // console.log("objektiIm", myObj);
      setObjToDb(myObj);

      axios
        .post("/general/login", myObj, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res?.data?.token) {
            window.localStorage.setItem("token", res.data.token);
            const user = jwt(res.data.token);
            setUserID(user.id);
            window.localStorage.setItem("id", user.id);
            // console.log("login", user.id);
            // console.log("userIDD", userID);

            let data2 = {
              cat_id: reportType,
              municipalities_id: city,
              gov_agencies_id: agency,
              contact_phone: phoneNumber,
              contact_email: email,
              case_address: address,
              contact_info: contactInfo,
              case_date: date,
              case_al: languageEn,
              community: communitySend,
              gender: Gjinia,
              education: edukimi,
              employment_status: employment,
              age: age,
              username: username,
              pass: password,
              user_id: user.id,
            };
            // console.log("formData", data2);

            if (window.localStorage.getItem("id")) {
              axios
                .post("/mobile/report_cases", data2, {
                  headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                      "token"
                    )}`,
                  },
                })
                .then(async (res) => {
                  // handleContinue();

                  if (userID && mistake === 1) {
                    handleContinue();
                  }
                  await axios
                    .post(
                      "web/report_case_images/uploadImages/" + res?.data?.id,
                      fd,
                      {
                        headers: {
                          Authorization: `Bearer ${window.localStorage.getItem(
                            "token"
                          )}`,
                        },
                      }
                    )
                    .then((res) => {
                      console.log(res);
                    })
                    .catch((err) => {
                      console.log(err);
                    });

                  if (res?.data && userID && mistake == 0) {
                    toast.success("Success", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  }
                })

                .catch((err) => {
                  console.log(err);
                });
            }

            props.Clear();
            window.location.href = "/profile/" + user.id;
          } else if (res.data.error) {
            toast.error(`${res.data.error}`, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (
      loginData?.isRegister === false &&
      mistake == 0 &&
      window.localStorage.getItem("token") != null
    ) {
      let data2 = {
        cat_id: reportType,
        municipalities_id: city,
        gov_agencies_id: agency,
        contact_phone: phoneNumber,
        contact_email: email,
        case_address: address,
        contact_info: contactInfo,
        case_date: date,
        case_al: languageEn,
        community: communitySend,
        gender: Gjinia,
        education: edukimi,
        employment_status: employment,
        age: age,
        username: username,
        pass: password,
        user_id: window.localStorage.getItem("id"),
      };
      if (window.localStorage.getItem("id")) {
        axios
          .post("/mobile/report_cases", data2, {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          })
          .then(async (res) => {
            // handleContinue();
            setUserID(window.localStorage.getItem("id"));

            if (userID && mistake === 1) {
              handleContinue();
            }
          
            await axios
              .post(
                "web/report_case_images/uploadImages/" + res?.data?.id,
                fd,
                {
                  headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                      "token"
                    )}`,
                  },
                }
              )
              .then((res) => { 
                console.log(res);
              })
              .catch((err) => {
                console.log(err);
              });

            if (
              res?.data &&
              window.localStorage.getItem("id") &&
              mistake == 0
            ) {
              toast.success("Success", {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }
            // props.Clear();
            window.location.href =
              "/profile/" + window.localStorage.getItem("id");
          })

          .catch((err) => {
            console.log(err);
          });
      }
    } else if (
      loginData?.isRegister === true &&
      mistake === 0
      // window.localStorage.getItem("token") != null
    ) {
      const returnedTarget = Object.assign(myObj, objToDB);

      returnedTarget.username = loginData.username;
      returnedTarget.pass = loginData.pass;
      setObjToDb(myObj);

      axios
        .post("/general/register", myObj)
        .then((res) => {
          // console.log("register", res?.myObj);

          if (res?.data === "User exists!") {
            toast.error(`${res.data}`, {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else
            axios
              .post("/general/login", myObj, {
                headers: {
                  Authorization: `Bearer ${window.localStorage.getItem(
                    "token"
                  )}`,
                },
              })
              .then((res) => {
                if (res?.data?.token) {
                  window.localStorage.setItem("token", res.data.token);
                  const user = jwt(res.data.token);
                  setUserID(user.id);
                  window.localStorage.setItem("id", user.id);
                  window.location.href = "/profile/" + user.id;
                  // console.log("login", user.id);
                  // console.log("userIDD", userID);

                  let data2 = {
                    cat_id: reportType,
                    municipalities_id: city,
                    gov_agencies_id: agency,
                    contact_phone: phoneNumber,
                    contact_email: email,
                    case_address: address,
                    contact_info: contactInfo,
                    case_date: date,
                    case_al: languageEn,
                    community: communitySend,
                    gender: Gjinia,
                    education: edukimi,
                    employment_status: employment,
                    age: age,
                    username: username,
                    pass: password,
                    user_id: user.id,
                  };
                  // console.log("formData", data2);

                  if (window.localStorage.getItem("id")) {
                    axios
                      .post("/mobile/report_cases", data2, {
                        headers: {
                          Authorization: `Bearer ${window.localStorage.getItem(
                            "token"
                          )}`,
                        },
                      })

                      .then(async (res) => {
                        // handleContinue();

                        if (userID && mistake === 1) {
                          handleContinue();
                        }
                       await axios
                          .post(
                            "web/report_case_images/uploadImages/" +
                            res?.data?.id,
                            fd,
                            {
                              headers: {
                                Authorization: `Bearer ${window.localStorage.getItem(
                                  "token"
                                )}`,
                              },
                            }
                          )
                          .then((res) => {
                            console.log(res);
                          })
                          .catch((err) => {
                            console.log(err);
                          });

                        if (res?.data && userID && mistake == 0) {
                          toast.success("Success", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                          });
                        }
                      })

                      .catch((err) => {
                        console.log(err);
                      });
                  }
                } else if (res.data.error) {
                  toast.error(`${res.data.error}`, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error(` ${translateMe("fillRaportData", props?.landId)} `, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  useEffect(() => {
    let token = window.localStorage.getItem("token");
    axios
      .get("general/municipalities/all")
      .then((response1) => {
        // console.log("dataa123a", response1?.data);
        setmunicipalities(response1?.data);
        return axios.get("general/gov_agencies/all");
      })
      .then((response2) => {
        // console.log("Response1", response2.data);
        setagencyget(response2.data);
        return axios.get("general/raport_categories/all");
      })
      .then((response3) => {
        // console.log("Response2", response3.data);
        setraport(response3.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("/mobile/user/" + window.localStorage.getItem("id"), {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setUser(response);
        // console.log("usertest", response);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("/general/community/all")
      .then((res) => {
        setcommunity(res?.data);
        // console.log("setcommunity", res);
      })
      .catch((err) => console.log("error", err));
  }, []);

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <div className="container col-md-6 offset-md-3 mt-5 mb-5">
      <h3 className="mb-4 reportTitle ">
        {translateMe("reportCaseTitle", props?.landId)}{" "}
      </h3>
      <h6 className="mb-2 reportTitle">
        {translateMe("yourDataInfo", props?.landId)}{" "}
      </h6>
      <form>
        <select
          id="select"
          onChange={handleReport}
          className="form-select my-2 py-2"
          aria-label="Default select example"
        >
          <option selected disabled>
            {translateMe("reportInfo", props?.landId)}{" "}
          </option>

          {raport?.map((el) => {
            return (
              <option
                value={el.id}
                selected={reportType == el.id ? "selected" : ""}
                key={el.id}
              >
               {translateMe(el.name_al, props?.landId)}
              </option>
            );
          })}
        </select>
        <div className="text-danger">
          <p>{errorR}</p>
        </div>

        <select
          onChange={handlecity}
          className="form-select my-2 py-2"
          aria-label="Default select example"
        >
          <option selected disabled>
            {translateMe("city", props?.landId)}{" "}
          </option>

          {municipalities?.map((el) => {
            return (
              <option
                value={el.id}
                selected={city == el.id ? "selected" : null}
                key={el.id}
              >
                {el.name_al}
              </option>
            );
          })}
        </select>
        <div className="text-danger">
          <p>{errorC}</p>
        </div>
        <select
          id="select"
          className="form-select my-2 py-2"
          aria-label="Default select example"
          onChange={handlecommunity}
        >
          <option selected disabled>
            {translateMe("community", props?.landId)}{" "}
          </option>

          {community?.map((el) => (
            <option
              value={el?.id}
              selected={communitySend == el.id ? "selected" : null}
            >
              {translateMe(el.name_al, props?.landId)}
            </option>
          ))}
        </select>

        <div className="text-danger">
          <p>{errorCO}</p>
        </div>
        <div className="mb-3">
          <label for="addressInput" className="form-label">
            {translateMe("generalAddress", props?.landId)}
          </label>

          <input
            name="fullname"
            id="fullname"
            type="text"
            className="form-control w-100"
            defaultValue={address}
            onChange={handleAddress}
          />
        </div>
        <div className="text-danger">
          <p>{errorAd}</p>
        </div>

        <select
          onChange={handleagency}
          className="form-select my-2 py-2"
          aria-label="Default select example"
        >
          <option selected disabled>
            {translateMe("agency", props?.landId)}{" "}
          </option>
          {agencyget?.map((el) => {
            return (
              <option
                value={el.id}
                selected={agency == el.id ? "selected" : null}
                key={el.id}
              >
              {translateMe(el.name_al, props?.landId)}
              </option>
            );
          })}
        </select>
        <div className="text-danger">
          <p>{errorA}</p>
        </div>
        <label>{translateMe("reportDetails", props?.landId)}</label>
        <textarea
          type="text"
          className="form-control"
          onChange={handleMessage}
          defaultValue={languageEn}
        />
        <div className="text-danger">
          <p>{errorL}</p>
        </div>
        <div className="date d-flex justify-content-center space-between mb-3 mt-3">
          <label for="date" className="form-label w-100 mt-3">
            {translateMe("reportDate", props?.landId)}{" "}
          </label>
          <input
            type="date"
            className="dateID w-50"
            onChange={handledate}
            value={date}
            max={currentDate}
          />
        </div>
        <div className="text-danger">
          <p>{errorD}</p>
        </div>

        <div className="mt-3 mb-3 row">
          <p className="me-4 "> {translateMe("gender", props?.landId)} </p>

          <label for="f-option" className="l-radio">
            <input
              onChange={handlegjinia}
              value="Male"
              type="radio"
              id="f-option"
              name="selektor"
              tabindex="1"
              checked={Gjinia === "Male"}
            />

            <span>{translateMe("male", props?.landId)} </span>
          </label>

          <label for="s-option" className="l-radio">
            <input
              onChange={handlegjinia}
              value="Female"
              type="radio"
              id="s-option"
              name="selektor"
              tabindex="2"
              checked={Gjinia === "Female"}
            />
            <span>{translateMe("female", props?.landId)} </span>
          </label>
          <label for="d-option" className="l-radio">
            <input
              onChange={handlegjinia}
              value="other"
              type="radio"
              id="d-option"
              name="selektor"
              tabindex="3"
              checked={Gjinia === "other"}
            />
            <span>{translateMe("other", props?.landId)} </span>
          </label>
        </div>

        <div className="text-danger">
          <p>{errorG}</p>
        </div>
        <div className="mb-3">
          <label for="exampleInputEmail1" className="form-label">
            {translateMe("age", props?.landId)}{" "}
          </label>
          <input
            name="addresa"
            type="number"
            className="form-control"
            aria-describedby="emailHelp"
            onChange={handleage}
            defaultValue={age}
          />
        </div>
        <div className="text-danger">
          <p>{errorV}</p>
        </div>
        <span className="text-muted fst-italic">{translateMe("optional", props?.landId)}</span>
        <select
          id="select"
          className="form-select my-2 py-2 "
          aria-label="Default select example"
          onChange={handleEduaction}
          defaultValue={edukimi}
        >
          <option value="" disabled selected >
            {" "}
            {translateMe("education", props?.landId)}
          </option>
          {/* <option selected>
            {translateMe("education", props?.landId)}{" "}
          </option> */}

          <option value="Primary school">
            {" "}
            {translateMe("primarySchool", props?.landId)}{" "}
          </option>
          <option value="Secondary school">
            {" "}
            {translateMe("secondarySchool", props?.landId)}{" "}
          </option>
          <option value="University">
            {" "}
            {translateMe("university", props?.landId)}{" "}
          </option>
        </select>
        <div className="text-danger">
          <p>{errorU}</p>
        </div>
        <span className="text-muted fst-italic">{translateMe("optional", props?.landId)}</span>
        <select
          id="select"
          className="form-select my-2 py-2"
          aria-label="Default select example"
          onChange={handleEmployment}
          defaultValue={employment}
        >
          <option value="" selected disabled>
            {translateMe("employmentStatus", props?.landId)}{" "}
          </option>

          <option value="Employed">
            {translateMe("employed", props?.landId)}{" "}
          </option>
          <option value="Unemployed">
            {translateMe("unemployed", props?.landId)}{" "}
          </option>
        </select>
        <div className="text-danger">
          <p>{errorP}</p>
        </div>

        <span className="text-muted fst-italic">{translateMe("optional", props?.landId)}</span>
        <div className="mb-3">
          <label for="exampleInputEmail1" className="form-label">
            {translateMe("email", props?.landId)}{" "}
          </label>
          <input
            onChange={handleEmail}
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            defaultValue={email}
          />
        </div>
        <div className="text-danger">
          <p>{errorE}</p>
        </div>
        <span className="text-muted fst-italic">{translateMe("optional", props?.landId)}</span>
        <div className="mb-3">
          <label className="form-label" for="typePhone">
            {translateMe("yourPhoneNumber", props?.landId)}{" "}
          </label>
          <input
            onChange={handleNumber}
            type="tel"
            id="typePhone"
            className="form-control"
            value={phoneNumber}
          />
        </div>
        <div className="">
          <span className="text-muted fst-italic">{translateMe("optional", props?.landId)}</span>
          <p>{translateMe("upload", props?.landId)} </p>
          <label style={{ padding: "5px 10px", cursor: "pointer" }}>
            {/* <i
              className="fa fa-picture-o p-1 pe-3"
              style={{ color: "#0d6efd", fontSize: "22px" }}
            /> */}
            <input
              type="file"
              className="form-control choose"
              onChange={handleImg}
              multiple
            />
          </label>
        </div>
        <input id="filePicker" style={{ visibility: "hidden" }} type={"file"} />
        <label className="custom-file-input" for="Upload" />
        <label className="mb-2 col-12">
          {" "}
          {translateMe("contactInformation", props?.landId)}{" "}
        </label>

        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault1"
            onChange={handleContactInfo}
            checked={contactInfo === "true"}
            value={true}
          />
          <label className="form-check-label" for="flexRadioDefault1">
            {translateMe("yes", props?.landId)}{" "}
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="flexRadioDefault"
            id="flexRadioDefault2"
            onChange={handleContactInfo}
            checked={contactInfo === "false"}
            value={false}
          />
          <label className="form-check-label mb-3" for="flexRadioDefault2">
            {translateMe("no", props?.landId)}{" "}
          </label>
        </div>
        <div className="text-danger">
          <p>{errorCon}</p>
        </div>
        {window.localStorage.getItem("token") != null && (
          <div
            type="submit"
            className="btn continueBtn w-100"
            onClick={(event) => handleContinue(event)}
            // data-bs-toggle={mistake === 0 ? "modal" : ""}
            data-bs-target={
              localStorage.getItem("token") !== null ? "" : "#login-or-register"
            }
          // data-bs-target={
          //   localStorage.getItem("token") !== null
          //     ? ""
          //     : " #login-or-register"
          // }
          >
            {translateMe("continue", props?.landId)}{" "}
          </div>
        )}
        {window.localStorage.getItem("token") === null && (
          <div className="divpalidhje ">
            <div className="">
              <div className="loginDataRegister row">
                <div className="col-6 registerOrLogin fw-bold">
                  <div
                    className={
                      loginData.isRegister === false
                        ? "lang-btn-active"
                        : "lang-btn"
                    }
                    onClick={() => {
                      // e.preventDefault();
                      setLoginData({ ...loginData, isRegister: false });
                    }}
                  >
                    {translateMe("login", props?.landId)}
                  </div>
                </div>
                <div className="col-6 registerOrLogin fw-bold">
                  <div
                    className={
                      loginData.isRegister === true
                        ? "lang-btn-active"
                        : "lang-btn"
                    }
                    onClick={() => {
                      // e.preventDefault();
                      setLoginData({ ...loginData, isRegister: true });
                    }}
                  >
                    {translateMe("signup", props?.landId)}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {loginData.isRegister === false ? (
                <form>
                  <div className="mb-3">
                    <h6 className="mb-3 reportTitle">
                      {" "}
                      {translateMe("fillLoginData", props?.landId)}
                    </h6>
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {translateMe("username", props?.landId)}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      name="name"
                      onChange={(e) =>
                        setLoginData({ ...loginData, username: e.target.value })
                      }
                    />
                    <p className="text-danger">{errorAd}</p>
                    <div className="mb-3 ">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        {translateMe("password", props?.landId)}
                      </label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        onChange={(e) =>
                          setLoginData({ ...loginData, pass: e.target.value })
                        }
                      />
                      <p className="text-danger">{errorPassword}</p>
                    </div>
                  </div>
                  <button
                    className="LoginBtn w-100"
                    onClick={(event) => handleContinue(event)}
                  >
                    {translateMe("login", props?.landId)}
                  </button>
                </form>
              ) : (
                <form>
                  <div className="mb-3">
                    <h6 className="mb-3 reportTitle">
                      {" "}
                      {translateMe("fillRegisterData", props?.landId)}
                    </h6>

                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {translateMe("username", props?.landId)}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      name="name"
                      onChange={(e) =>
                        setLoginData({
                          ...loginData,
                          username: e.target.value,
                        })
                      }
                    ></input>
                  </div>
                  <div className="mb-3 ">
                    <label
                      htmlFor="exampleInputPassword1"
                      className="form-label"
                    >
                      {translateMe("password", props?.landId)}
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      name="password"
                      onChange={(e) =>
                        setLoginData({
                          ...loginData,
                          pass: e.target.value,
                        })
                      }
                    ></input>
                  </div>

                  <button
                    className="LoginBtn w-100"
                    onClick={(event) => handleContinue(event)}
                  >
                    {translateMe("signup", props?.landId)}
                  </button>
                </form>
              )}
            </div>
          </div>
        )}
      </form>
    </div >
  );
};

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    setReportData: (data) => dispatch(setReportData(data)),
    Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportCase);
