import { React, useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import {
    FacebookIcon,
    TwitterIcon,
    FacebookShareButton,
    TwitterShareButton, LinkedinIcon, LinkedinShareButton
} from "react-share";
import "./share.scss";

const Share = () => {
    const [clicked, setClicked] = useState(false);
    const { pathname } = useLocation();

    const shareUrl = `https://raportodiskriminimin.org/${pathname}`;

    useEffect(() => {
        setClicked(false);
    }, [pathname]);

    return (
        <>
            <div className=' d-flex flex-row-reverse ikona '>
                {clicked ? <div className="ikonatt">
                    <div className="shareikona" onClick={() => setClicked(!clicked)}>
                        <FacebookShareButton
                            url={shareUrl}
                        >
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                    </div>
                    <div className="shareikona" onClick={() => setClicked(!clicked)}>

                        <TwitterShareButton
                            url={shareUrl}
                        >
                            <TwitterIcon size={32} round />
                        </TwitterShareButton>
                    </div>

                    <div className="shareikona mb-3" onClick={() => setClicked(!clicked)}>
                        <LinkedinShareButton
                            url={shareUrl}
                        >
                            <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                    </div>
                </div> : ""}
                <div className="iconStyle">

                    <div className=' rounded-circle mainIcon' onClick={() => setClicked(!clicked)}>
                        <i className="fa fa-share" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Share