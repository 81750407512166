import React, { useState, useEffect } from "react";
import "./News.scss";
import { Link } from "react-router-dom";
import axios from "../../axios";
import { connect } from "react-redux";
import {
  FacebookIcon,
  TwitterIcon,
  FacebookShareButton,
  TwitterShareButton
} from "react-share";


const News = (propss) => {
  const [news, setnews] = useState([]);
  const shareUrl = "https://raportodiskriminimin.org/";
  // const title = "Raporto Diskriminimin";
  const translateMe = (key, langId) => {
    let diqka = propss?.langData?.filter((el) => el.key == key)[0];
    if (langId === "al") {
      return diqka?.content_al;
    }
    if (langId === "sr") {
      return diqka?.content_sr;
    }
    if (langId === "en") {
      return diqka?.content_en;
    }
  };

  useEffect(() => {
    // console.log("dionn");
    axios
      .get("/general/news/all")
      .then((res) => {
        // console.log("dataaa", res?.data);
        setnews(res?.data.slice(0, 3));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <section className="news new-bg-color">
        <div className="new-bgcolor">
          <div className="container">
            <h2>{translateMe("newsTitle", propss?.landId)}</h2>
            <div className="row justify-content-center res-img">
              {news?.map((el) => {
                if (propss?.landId == "al") {
                  return (
                    <>
                      <div className="col-lg-4 col-md-6 col-10 mb-5">
                        <div className="card shadow border-img card-height ">
                          <Link to={{ pathname: "/news/" + el.id }}>
                            <img
                              src={`${process.env.REACT_APP_UPLOADS}` + el.pic_url}
                              className="card-img-top"
                              alt="..."
                            />
                            <div className="card-body text-single ">
                              <h5 className="card-title fw-bold">{el.name_al}</h5>
                              <p
                                className="card-text "
                                dangerouslySetInnerHTML={{
                                  __html: el.desc_al.slice(0, 180) + "...",
                                }}
                              ></p>
                              <div />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </>
                  );
                }
                if (propss?.landId == "en") {
                  return (
                    <>
                      <div className="col-lg-4 col-md-6 col-10 mb-5">
                        <div className="card shadow border-img card-height ">
                          <Link to={{ pathname: "/news/" + el.id }}>
                            <img
                              src={`${process.env.REACT_APP_UPLOADS}` + el.pic_url}
                              className="card-img-top"
                              alt="..."
                            />
                            <div className="card-body text-single ">
                              <h5 className="card-title fw-bold text-dark">{el.name_en}</h5>
                              <p
                                className="card-text "
                                dangerouslySetInnerHTML={{
                                  __html: el.desc_en.slice(0, 100) + "...",
                                }}
                              ></p>
                              <div />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </>
                  );
                }
                if (propss?.landId == "sr") {
                  return (
                    <>
                      <div className="col-lg-4 col-md-6 col-10 mb-5">
                        <div className="card shadow border-img card-height ">
                          <img
                            src={`${process.env.REACT_APP_UPLOADS}` + el.pic_url}
                            className="card-img-top"
                            alt="..."
                          />
                          <div className="card-body text-single ">
                            <Link to={{ pathname: "/news/" + el.id }}>
                              <h5 className="card-title fw-bold">{el.name_sr}</h5>
                            </Link>
                            <p
                              className="card-text "
                              dangerouslySetInnerHTML={{
                                __html: el.desc_sr.slice(0, 180) + "...",
                              }}
                            ></p>
                            <div />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                }
              })}
            </div>
            <div className="btn-news text-center">
              <Link to={{ pathname: "/news" }} className="btn">
                {translateMe("showMore", propss?.landId)}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // loggedIn: state.data.loggedIn,
    // reportData: state.data.reportData,
    landId: state.data.langId,
    langData: state.data.langData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // logMeIn: () => dispatch(logMeIn()),
    // setReportData: (data) => dispatch(setReportData(data)),
    // Clear: () => dispatch(Clear()),
    // setLangID: (id) => dispatch(setLangID(id)),
    // setLangData: (data) => dispatch(setLangData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(News);
